import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography, MediaImageStandard, LinkButton } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import Section from 'components/Section'
import { Grid } from '@achieve/ascend'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'

function CloserStatement({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)

  const { title, image, button, altCta } = useMemoizedContentGetter(content, [
    'title',
    'image',
    'button',
    'altCta',
  ])
  return (
    <Section
      className={
        // styles['closer-statement-section']
        'bg-[#30f] text-white text-center max-h-[520px] flex overflow-hidden xl:text-left xl:h-[339px]'
      }
      data-testid="closer-statement-section"
    >
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        className={
          // styles['outer-grid-container']
          'pt-8 xl:max-w-[920px] xl:h-[339px] xl:m-auto xl:pt-0'
        }
        data-testid="closer-statement-outer-grid-container"
      >
        <Grid
          item
          xs={8}
          xl={6.5}
          className={
            // styles['grid-item-text']
            'order-1'
          }
          data-testid="closer-statement-grid-item-text"
        >
          <div
            className={
              // styles['text-container']
              'xl:max-w-[450px] xl:p-0'
            }
            data-testid="closer-statement-text-container"
          >
            <Typography
              content={title?.textContent}
              variant="displayLg"
              fontWeight="bold"
              className={
                // styles['title']
                'leading-[140%] pb-6 xl:pb-8'
              }
            />
            <div
              className={
                // styles['cta-container']
                '[&_.button]:z-[1] [&_.button]:mx-[2.4px] [&_.button]:my-0 [&_.button]:px-6 [&_.button]:py-3 xl:flex xl:m-0.5 xl:[&_.button]:py-2 xl:[&_.button]:px-5'
              }
            >
              {button && (
                <LinkButton
                  className={
                    // styles['button']
                    'button'
                  }
                  content={button}
                  typographicOptions={{
                    variantOverride: {
                      [BLOCKS.PARAGRAPH]: isMobile ? 'bodySm' : 'bodyLg',
                    },
                    fontWeight: 'bold',
                    'data-testid': 'closer-statement-button-text',
                  }}
                  color="secondary"
                  data-testid="closer-statment-button"
                  track={{
                    ...{
                      click_id: button?.linkText?.json?.content?.[0]?.content[0]?.value,
                      list_name: 'Product Page Closer',
                      nav_link_section: 'Closer Statement',
                      track_event: 'internal_campaign_click',
                      click_type: 'Button Click',
                      event_action: 'button_click',
                      event_type: 'button_click',
                    },
                    ...button.event,
                  }}
                />
              )}
              {Object.hasOwnProperty.call(altCta?.json || {}, 'textContent') && (
                <Typography
                  content={altCta?.textContent}
                  variant="bodySm"
                  fontWeight="bold"
                  className={
                    // styles['alt-cta']
                    'self-center pt-10 xl:pl-10 xl:pr-0 xl:py-0'
                  }
                  data-testid="closer-statement-text-area-alt-cta"
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          xl={5.5}
          className={
            // styles['grid-item-image']
            'order-2 w-[inherit] h-[inherit] flex justify-center items-end pt-6'
          }
          data-testid="closer-statement-grid-item-image"
        >
          <MediaImageStandard
            objectFit="contain"
            objectPosition="center bottom"
            height={isMobile ? 250 : 350}
            content={image?.mediaContent}
            alt={image?.mediaAltText}
            data-testid="closer-statement-image"
            placeholder={'empty'}
            className={
              // styles['image']
              'object-contain object-[center_bottom] max-h-[250px] xl:max-h-[stretch]'
            }
          />
        </Grid>
      </Grid>
    </Section>
  )
}
export { CloserStatement }
export default CloserStatement
